import React from "react";
import {
  SEO,
  PageLayout,
  HeroElement,
  Contact,
  ContactForm,
} from "@bluefin/components";
import { Grid } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getPageHeroImage } from "../utils/utils";

export default class ContactPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          images={getPageHeroImage({
            options: fishermanBusinessWebsitePage.components,
          })}
        />
        <PageLayout
          hero={
            <HeroElement
              header={"Contact"}
              tagline={""}
              images={getPageHeroImage({
                options: fishermanBusinessWebsitePage.components,
              })}
              ctas={[
                fishermanBusiness.ctas.address,
                fishermanBusiness.ctas.phone,
              ]}
              ctaInverted={true}
              ctaColor={"white"}
              containerColor={"primary"}
              textColor={"white"}
              showMultiple={true}
              overlay={true}
              fullWidth={true}
              parallax={false}
              containerAs={"none"}
              height={500}
              gutter={false}
            />
          }
        >
          <Grid stackable={true} className={"component-section-container"}>
            <Grid.Column width={16}>
              <Contact
                businessName={fishermanBusiness.name}
                phoneNumber={fishermanBusiness.primaryLocation.phoneNumber}
                hours={fishermanBusiness.primaryLocation.hours}
                address={{
                  street: fishermanBusiness.primaryLocation.street,
                  city: fishermanBusiness.primaryLocation.city,
                  state: fishermanBusiness.primaryLocation.state,
                  zip: fishermanBusiness.primaryLocation.zipCode,
                }}
                as={"text"}
                buttonColor={"secondary"}
                header={"Contact"}
                image={""}
                showMap={true}
              />
              <div className={"ui container additional-text"}>
                <div className={"ui stackable grid"}>
                  <div className={"eleven wide column contact-graphic"} />
                  <div
                    className={
                      "middle aligned four wide column contact-content"
                    }
                  >
                    <div className={"additionalInfos"}>
                      <p>Please call (207) 573-1142 for hours of operation</p>
                    </div>
                  </div>
                </div>
              </div>
            </Grid.Column>
          </Grid>
          <Grid
            stackable={true}
            textAlign={"center"}
            className={"component-section-container"}
          >
            <Grid.Column computer={12} mobile={16}>
              <ContactForm
                buttonText={"Send"}
                centeredHeaders={true}
                className={""}
                fluidButton={true}
                header={"Contact Us"}
                inline={false}
                showLabels={true}
                subheader={""}
                termsCopy={""}
                withContainer={true}
                locationId={fishermanBusiness.primaryLocation._id}
                businessId={fishermanBusiness._id}
                businessType={fishermanBusiness.type}
              />
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      ctas {
        address {
          text
          link
          internal
        }
        phone {
          text
          link
          internal
        }
      }
      primaryLocation {
        phoneNumber
        hours {
          open
          close
          label
          day
        }
        street
        city
        state
        zipCode
        _id
      }
      _id
      type
    }
    fishermanBusinessWebsitePage(title: { eq: "Contact" }) {
      title
      components {
        fastId
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`;
